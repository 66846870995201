<template>
  <div>
    <validation-observer ref="observer">
      <form @submit.prevent="save">
        <div class="task-container pa-9 pa-md-6 pa-lg-9 mx-10">
          <v-container>
            <v-row align="center">
              <v-col cols="12" class="mb-2">
                <h3 class="page-title font-weight-bold">
                  パートナー会社の編集
                </h3>
                <span>協力会社情報の編集を行います。</span>
              </v-col>

              <template v-if="loading">
                <v-col cols="12">
                  <div class="form-content">
                    <v-container>
                      <v-skeleton-loader
                        class="mb-6"
                        type="card-avatar, article, actions"
                      ></v-skeleton-loader>
                    </v-container>
                  </div>
                </v-col>
              </template>
              <template v-else>
                <v-col offset-md="3" cols="3" class="mb-2">
                  <img
                    :src="preview_file"
                    width="100%"
                    class="image_preview mb-2"
                  />
                  <div class="d-flex">
                    <v-btn text class="red--text" @click="removeFile()"
                      >ｰ 削除</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn text class="blue--text pointer" @click="openFile()"
                      >+ アップロード
                    </v-btn>
                  </div>
                  <validation-provider
                    v-slot="{ validate, errors }"
                    name="画像ファイル"
                    rules="image|ext:jpeg,jpg,png,gif|size:5120|min-dimensions:100,100"
                    ref="imageValidation"
                    :custom-messages="{
                      image: '画像ファイルを選択して下さい。',
                      ext: '画像ファイルはjpg, png, gifを選択して下さい。',
                      'min-dimensions':
                        '100px x 100px以上の画像を使用して下さい。',
                      size: '5Mb以下のファイルを使用して下さい。'
                    }"
                  >
                    <input
                      type="file"
                      style="display: none"
                      ref="uploader"
                      accept="image/*"
                      @change="onFilePicked"
                    />
                    <p v-if="errors.length" class="error--text">
                      {{ errors[0] }}
                    </p>
                  </validation-provider>
                </v-col>
                <v-col cols="12" id="dateTimePart">
                  <div class="form-content">
                    <v-row align="center" v-if="data.type === 3">
                      <v-col
                        cols="3"
                        class="form-header text-heading-3 text-right"
                      >
                        表示順
                      </v-col>
                      <v-col cols="7">
                        <validation-provider
                          v-slot="{ errors }"
                          name="display_order"
                          rules="between:1,99"
                        >
                          <v-text-field
                            :error-messages="errors"
                            :error="errors.length !== 0"
                            v-model.number="data.display_order"
                            type="number"
                            class="form-text"
                            dense
                            hide-details="auto"
                            outlined
                            placeholder="1~99の数字を一つ入力してください。"
                            :disabled="disableForm"
                          >
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col
                        cols="3"
                        class="form-header text-heading-3 text-right"
                      >
                        パートナー会社のタイプ
                      </v-col>
                      <v-col cols="7" class="flex-grow-1">
                        <v-select
                          v-model="data.type"
                          :items="types"
                          class="form-text"
                          dense
                          hide-details
                          outlined
                          placeholder="タイプを選択してください。"
                          :disabled="disableForm"
                          @change="handleType()"
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-row align="center">
                      <v-col
                        cols="3"
                        class="form-header text-heading-3 text-right"
                      >
                        会社名
                      </v-col>
                      <v-col cols="7" class="flex-grow-1">
                        <validation-provider
                          v-slot="{ errors }"
                          name="name"
                          rules="required"
                        >
                          <v-text-field
                            v-model="data.name"
                            :error-messages="errors"
                            :error="errors.length !== 0"
                            class="form-text"
                            dense
                            hide-details
                            outlined
                            placeholder="会社名を入力してください。"
                            :disabled="disableForm"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row align="center">
                      <v-col
                        cols="3"
                        class="form-header text-heading-3 text-right"
                      >
                        郵便番号
                      </v-col>
                      <v-col cols="4" class="flex-grow-1">
                        <v-text-field
                          v-model="data.postcode"
                          @input="changePostal"
                          class="form-text"
                          dense
                          hide-details
                          outlined
                          placeholder="0000000"
                          :disabled="disableForm"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row align="center">
                      <v-col
                        cols="3"
                        class="form-header text-heading-3 text-right"
                      >
                        都道府県
                      </v-col>
                      <v-col cols="4" class="flex-grow-1">
                        <v-select
                          v-model="data.prefecture_id"
                          :items="prefectures"
                          item-text="name"
                          item-value="id"
                          class="form-text"
                          dense
                          hide-details
                          outlined
                          placeholder="都道府県を選択してください。"
                          :disabled="disableForm"
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-row align="center">
                      <v-col
                        cols="3"
                        class="form-header text-heading-3 text-right"
                      >
                        市区町村
                      </v-col>
                      <v-col cols="4" class="flex-grow-1">
                        <v-text-field
                          v-model="data.city"
                          class="form-text"
                          dense
                          hide-details
                          outlined
                          placeholder="市区町村を入力してください。"
                          :disabled="disableForm"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row align="center">
                      <v-col
                        cols="3"
                        class="form-header text-heading-3 text-right"
                      >
                        番地・建物名・号室
                      </v-col>
                      <v-col cols="7" class="flex-grow-1">
                        <v-text-field
                          v-model="data.address"
                          class="form-text"
                          dense
                          hide-details
                          outlined
                          placeholder="番地・建物名・号室 を入力してください。"
                          :disabled="disableForm"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row align="center">
                      <v-col
                        cols="3"
                        class="form-header text-heading-3 text-right"
                      >
                        電話番号
                      </v-col>
                      <v-col cols="7" class="flex-grow-1">
                        <v-text-field
                          v-model="data.phone"
                          class="form-text"
                          dense
                          hide-details
                          outlined
                          placeholder="00000000000"
                          :disabled="disableForm"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row align="center">
                      <v-col
                        cols="3"
                        class="form-header text-heading-3 text-right"
                      >
                        メールアドレス
                      </v-col>
                      <v-col cols="7" class="flex-grow-1">
                        <v-text-field
                          v-model="data.email"
                          class="form-text"
                          dense
                          hide-details
                          outlined
                          placeholder="abc@example.com"
                          :disabled="disableForm"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                        cols="3"
                        class="form-header text-heading-3 text-right"
                      >
                        メモ
                      </v-col>
                      <v-col cols="7" class="flex-grow-1">
                        <v-textarea
                          v-model="data.memo"
                          class="form-text"
                          counter="500"
                          hide-details
                          outlined
                          placeholder="協力会社に関するメモを記入してください。"
                          :disabled="disableForm"
                          rows="7"
                        ></v-textarea>
                      </v-col>
                    </v-row>

                    <v-row align="center">
                      <v-col
                        cols="3"
                        class="form-header text-heading-3 text-right"
                      >
                        担当者名
                      </v-col>
                      <v-col cols="7" class="flex-grow-1">
                        <v-text-field
                          v-model="data.name_of_person_in_charge"
                          class="form-text"
                          dense
                          hide-details
                          outlined
                          placeholder="担当者名を入力してください。"
                          :disabled="disableForm"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row align="center">
                      <v-col
                        cols="3"
                        class="form-header text-heading-3 text-right"
                      >
                        担当者名フリガナ
                      </v-col>
                      <v-col cols="7" class="flex-grow-1">
                        <v-text-field
                          v-model="data.furigana_name_of_person_in_charge"
                          class="form-text"
                          dense
                          hide-details
                          outlined
                          placeholder="担当者フリガナを入力してください。"
                          :disabled="disableForm"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row align="center">
                      <v-col
                        cols="3"
                        class="form-header text-heading-3 text-right"
                      >
                        担当者メールアドレス
                      </v-col>
                      <v-col cols="7" class="flex-grow-1">
                        <v-text-field
                          v-model="data.email_address_of_person_in_charge"
                          class="form-text"
                          dense
                          hide-details
                          outlined
                          placeholder="abc@example.com"
                          :disabled="disableForm"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row align="center">
                      <v-col
                        cols="3"
                        class="form-header text-heading-3 text-right"
                      >
                        担当者電話番号
                      </v-col>
                      <v-col cols="7" class="flex-grow-1">
                        <v-text-field
                          v-model="data.mobile_number_of_person_in_charge"
                          class="form-text"
                          dense
                          hide-details
                          outlined
                          placeholder="00000000000"
                          :disabled="disableForm"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </template>
            </v-row>
          </v-container>
        </div>
        <div class="create-task-footer px-6">
          <v-row class="justify-end">
            <v-col cols="auto" class="flex-grow-1">
              <v-btn
                text
                color="#AD4545"
                rounded
                min-width="150"
                @click="deleteItem()"
                :loading="loading"
                :disabled="disableForm"
              >
                <v-icon left>
                  $trash
                </v-icon>
                削除する
              </v-btn>
            </v-col>

            <v-col cols="auto">
              <v-btn
                @click="$router.push('/management/partner')"
                text
                rounded
                min-width="150"
                class="mr-8"
                >キャンセル</v-btn
              >
            </v-col>

            <v-col cols="auto">
              <v-btn
                @click="save"
                color="#4F55A7"
                class="white--text"
                min-width="150"
                >パートナー会社を更新する
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'EditPartner',
  props: ['id'],
  data() {
    return {
      types: [
        { value: 0, text: 'マンション・ハウスメーカー' },
        { value: 1, text: '発注会社' },
        { value: 2, text: '元請会社' },
        { value: 3, text: '協力会社' }
      ],
      modal: {
        delete: false
      },
      loading: true,
      preview: null,
      file: null
    }
  },
  computed: {
    disableForm() {
      return this.loading
    },
    prefectures() {
      return this.$store.getters.allPrefectures
    },
    data() {
      return this.$store.getters.detailPartner
    },
    preview_file: {
      get() {
        if (this.preview) return this.preview

        return `${
          this.data?.profile_image
            ? this.data.profile_image_url
            : process.env.VUE_APP_BASEURL + 'images/userblank-rectangle.png'
        }`
      },
      set(value) {
        this.preview = value
      }
    }
  },
  created() {
    this.$store.dispatch('PREFECTURE_GET_ALL')
    this.$store.dispatch('PARTNER_GET', this.id).then(() => {
      this.loading = false
    })
    console.log(this.data)
  },
  methods: {
    ...mapMutations(['showModal']),
    changePostal() {
      var postal_code = require('japan-postal-code')
      this.resetPrefectureCity()

      postal_code.get(this.data.postcode, address => {
        let selected_prefecture = this.prefectures.filter(
          prefecture => address.prefecture === prefecture.name
        )[0]

        this.data.prefecture = selected_prefecture.name
        this.data.prefecture_id = selected_prefecture.id
        this.data.city = address.city
      })
    },
    resetPrefectureCity() {
      this.data.prefecture = null
      this.data.prefecture_id = null
      this.data.city = null
    },
    openFile() {
      this.$refs.uploader.click()
    },
    onFilePicked(e) {
      this.file = e.target.files[0]
      this.preview_file = URL.createObjectURL(this.file)
    },
    removeFile() {
      this.file = null
      this.preview_file = null
      this.data.profile_image = null
      this.data.profile_image_deleted = true
    },
    save() {
      this.loading = true
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return
        }
        let id = this.data.id
        let data = new FormData()
        data.append('_method', 'PUT')
        data.append('profile_image', this.file)
        for (const [key, value] of Object.entries(this.data)) {
          data.append(key, value ? value : value == 0 ? 0 : '')
        }
        this.$store
          .dispatch('PARTNER_UPDATE', { id, data })
          .then(
            response => {
              if (response.data.status == 'success') {
                this.$router.push({
                  path: '/management/partner',
                  query: {
                    status: 'success',
                    message: response.data.data.message
                  }
                })
              }
            },
            error => {
              this.$swal.fire({
                title: 'システムエラー',
                text:
                  '登録時に不具合が生じています。システム管理者へ問い合わせてください。',
                icon: 'error',
                confirmButtonText: '閉じる'
              })
              throw error
            }
          )
          .finally(() => {
            this.loading = false
          })
      })
    },
    deleteItem() {
      this.showModal({
        text: 'このアクションを実施してよろしいですか',
        action: {
          process: {
            color: 'red',
            text: '実施する'
          },
          cancel: {
            color: 'secondary',
            text: 'キャンセル'
          }
        },
        func: this.processDelete
      })
    },

    async processDelete() {
      this.loading = true
      await this.$store
        .dispatch('PARTNER_DELETE', { id: this.$route.params.id })
        .then(response => {
          if (response.status === 200) {
            this.$router.push({
              name: 'PartnerManagement',
              query: { status: 'success', message: response.data.data.message }
            })
          }
        })
      this.loading = false
    },
    handleType() {
      if (this.data.type !== 3 && this.data.hasOwnProperty('display_order')) {
        this.data.display_order = null
      }
    }
  }
}
</script>

<style src="./Create.scss" lang="scss" scoped></style>
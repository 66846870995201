var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('validation-observer', {
    ref: "observer"
  }, [_c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.save.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "task-container pa-9 pa-md-6 pa-lg-9 mx-10"
  }, [_c('v-container', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', {
    staticClass: "page-title font-weight-bold"
  }, [_vm._v(" パートナー会社の編集 ")]), _c('span', [_vm._v("協力会社情報の編集を行います。")])]), _vm.loading ? [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "form-content"
  }, [_c('v-container', [_c('v-skeleton-loader', {
    staticClass: "mb-6",
    attrs: {
      "type": "card-avatar, article, actions"
    }
  })], 1)], 1)])] : [_c('v-col', {
    staticClass: "mb-2",
    attrs: {
      "offset-md": "3",
      "cols": "3"
    }
  }, [_c('img', {
    staticClass: "image_preview mb-2",
    attrs: {
      "src": _vm.preview_file,
      "width": "100%"
    }
  }), _c('div', {
    staticClass: "d-flex"
  }, [_c('v-btn', {
    staticClass: "red--text",
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.removeFile();
      }
    }
  }, [_vm._v("ｰ 削除")]), _c('v-spacer'), _c('v-btn', {
    staticClass: "blue--text pointer",
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.openFile();
      }
    }
  }, [_vm._v("+ アップロード ")])], 1), _c('validation-provider', {
    ref: "imageValidation",
    attrs: {
      "name": "画像ファイル",
      "rules": "image|ext:jpeg,jpg,png,gif|size:5120|min-dimensions:100,100",
      "custom-messages": {
        image: '画像ファイルを選択して下さい。',
        ext: '画像ファイルはjpg, png, gifを選択して下さい。',
        'min-dimensions': '100px x 100px以上の画像を使用して下さい。',
        size: '5Mb以下のファイルを使用して下さい。'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var validate = _ref.validate,
            errors = _ref.errors;
        return [_c('input', {
          ref: "uploader",
          staticStyle: {
            "display": "none"
          },
          attrs: {
            "type": "file",
            "accept": "image/*"
          },
          on: {
            "change": _vm.onFilePicked
          }
        }), errors.length ? _c('p', {
          staticClass: "error--text"
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()];
      }
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "id": "dateTimePart"
    }
  }, [_c('div', {
    staticClass: "form-content"
  }, [_vm.data.type === 3 ? _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-heading-3 text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 表示順 ")]), _c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "display_order",
      "rules": "between:1,99"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('v-text-field', {
          staticClass: "form-text",
          attrs: {
            "error-messages": errors,
            "error": errors.length !== 0,
            "type": "number",
            "dense": "",
            "hide-details": "auto",
            "outlined": "",
            "placeholder": "1~99の数字を一つ入力してください。",
            "disabled": _vm.disableForm
          },
          model: {
            value: _vm.data.display_order,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "display_order", _vm._n($$v));
            },
            expression: "data.display_order"
          }
        })];
      }
    }], null, false, 2506785192)
  })], 1)], 1) : _vm._e(), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-heading-3 text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" パートナー会社のタイプ ")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "7"
    }
  }, [_c('v-select', {
    staticClass: "form-text",
    attrs: {
      "items": _vm.types,
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "placeholder": "タイプを選択してください。",
      "disabled": _vm.disableForm
    },
    on: {
      "change": function change($event) {
        return _vm.handleType();
      }
    },
    model: {
      value: _vm.data.type,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "type", $$v);
      },
      expression: "data.type"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-heading-3 text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 会社名 ")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "7"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "name",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-text-field', {
          staticClass: "form-text",
          attrs: {
            "error-messages": errors,
            "error": errors.length !== 0,
            "dense": "",
            "hide-details": "",
            "outlined": "",
            "placeholder": "会社名を入力してください。",
            "disabled": _vm.disableForm
          },
          model: {
            value: _vm.data.name,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "name", $$v);
            },
            expression: "data.name"
          }
        })];
      }
    }])
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-heading-3 text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 郵便番号 ")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    staticClass: "form-text",
    attrs: {
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "placeholder": "0000000",
      "disabled": _vm.disableForm
    },
    on: {
      "input": _vm.changePostal
    },
    model: {
      value: _vm.data.postcode,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "postcode", $$v);
      },
      expression: "data.postcode"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-heading-3 text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 都道府県 ")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    staticClass: "form-text",
    attrs: {
      "items": _vm.prefectures,
      "item-text": "name",
      "item-value": "id",
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "placeholder": "都道府県を選択してください。",
      "disabled": _vm.disableForm
    },
    model: {
      value: _vm.data.prefecture_id,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "prefecture_id", $$v);
      },
      expression: "data.prefecture_id"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-heading-3 text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 市区町村 ")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    staticClass: "form-text",
    attrs: {
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "placeholder": "市区町村を入力してください。",
      "disabled": _vm.disableForm
    },
    model: {
      value: _vm.data.city,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "city", $$v);
      },
      expression: "data.city"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-heading-3 text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 番地・建物名・号室 ")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "7"
    }
  }, [_c('v-text-field', {
    staticClass: "form-text",
    attrs: {
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "placeholder": "番地・建物名・号室 を入力してください。",
      "disabled": _vm.disableForm
    },
    model: {
      value: _vm.data.address,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "address", $$v);
      },
      expression: "data.address"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-heading-3 text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 電話番号 ")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "7"
    }
  }, [_c('v-text-field', {
    staticClass: "form-text",
    attrs: {
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "placeholder": "00000000000",
      "disabled": _vm.disableForm
    },
    model: {
      value: _vm.data.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "phone", $$v);
      },
      expression: "data.phone"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-heading-3 text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" メールアドレス ")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "7"
    }
  }, [_c('v-text-field', {
    staticClass: "form-text",
    attrs: {
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "placeholder": "abc@example.com",
      "disabled": _vm.disableForm
    },
    model: {
      value: _vm.data.email,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "email", $$v);
      },
      expression: "data.email"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "form-header text-heading-3 text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" メモ ")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "7"
    }
  }, [_c('v-textarea', {
    staticClass: "form-text",
    attrs: {
      "counter": "500",
      "hide-details": "",
      "outlined": "",
      "placeholder": "協力会社に関するメモを記入してください。",
      "disabled": _vm.disableForm,
      "rows": "7"
    },
    model: {
      value: _vm.data.memo,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "memo", $$v);
      },
      expression: "data.memo"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-heading-3 text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 担当者名 ")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "7"
    }
  }, [_c('v-text-field', {
    staticClass: "form-text",
    attrs: {
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "placeholder": "担当者名を入力してください。",
      "disabled": _vm.disableForm
    },
    model: {
      value: _vm.data.name_of_person_in_charge,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "name_of_person_in_charge", $$v);
      },
      expression: "data.name_of_person_in_charge"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-heading-3 text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 担当者名フリガナ ")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "7"
    }
  }, [_c('v-text-field', {
    staticClass: "form-text",
    attrs: {
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "placeholder": "担当者フリガナを入力してください。",
      "disabled": _vm.disableForm
    },
    model: {
      value: _vm.data.furigana_name_of_person_in_charge,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "furigana_name_of_person_in_charge", $$v);
      },
      expression: "data.furigana_name_of_person_in_charge"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-heading-3 text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 担当者メールアドレス ")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "7"
    }
  }, [_c('v-text-field', {
    staticClass: "form-text",
    attrs: {
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "placeholder": "abc@example.com",
      "disabled": _vm.disableForm
    },
    model: {
      value: _vm.data.email_address_of_person_in_charge,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "email_address_of_person_in_charge", $$v);
      },
      expression: "data.email_address_of_person_in_charge"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-heading-3 text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 担当者電話番号 ")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "7"
    }
  }, [_c('v-text-field', {
    staticClass: "form-text",
    attrs: {
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "placeholder": "00000000000",
      "disabled": _vm.disableForm
    },
    model: {
      value: _vm.data.mobile_number_of_person_in_charge,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "mobile_number_of_person_in_charge", $$v);
      },
      expression: "data.mobile_number_of_person_in_charge"
    }
  })], 1)], 1)], 1)])]], 2)], 1)], 1), _c('div', {
    staticClass: "create-task-footer px-6"
  }, [_c('v-row', {
    staticClass: "justify-end"
  }, [_c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "#AD4545",
      "rounded": "",
      "min-width": "150",
      "loading": _vm.loading,
      "disabled": _vm.disableForm
    },
    on: {
      "click": function click($event) {
        return _vm.deleteItem();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" $trash ")]), _vm._v(" 削除する ")], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "mr-8",
    attrs: {
      "text": "",
      "rounded": "",
      "min-width": "150"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/management/partner');
      }
    }
  }, [_vm._v("キャンセル")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "color": "#4F55A7",
      "min-width": "150"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("パートナー会社を更新する ")])], 1)], 1)], 1)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }